<template>
  <DataTable :value="list"
             :auto-layout="true"
             :paginator="true" :rows="10"
             paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
             :rowsPerPageOptions="[5,10,25]"
             currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计 {totalRecords} 记录">
    <template #header>
      <div style="display: flex">
        <Button class="p-mr-2"  @click="addOrUpdate">添加用户</Button>
        <InputText type="text" v-model="name" placeholder="查找用户名" @change="getData"></InputText>
        <Button @click="getData" class="p-ml-2">查询</Button>
      </div>
    </template>
    <Column field="id" header="编码"></Column>
    <Column field="name" header="名称"></Column>
    <Column field="addTime" header="添加时间">
      <template #body="a">
        {{new Date(a.data.addTime).toLocaleString()}}
      </template>
    </Column>
    <Column field="photo" header="电话"></Column>
    <Column field="remark" header="备注"></Column>
    <Column field="state" header="超级管理员">
      <template #body="a">
        {{a.data.state==1?'是':'否'}}
      </template>
    </Column>
    <Column header="操作">
      <template #body="a">
        <Button class="p-button-sm p-mr-2" @click="addOrUpdate(a.data)">编辑</Button>
        <Button class="p-button-sm p-mr-2"
                :class="{'p-button-danger':a.data.state==0}"
                @click="superUser(a.data.id)">
          {{a.data.state==0?'设置为超级管理员':'取消此超级管理员'}}</Button>
        <Button class="p-button-sm p-button-danger p-mr-2" @click="deleteUser(a.data.id)">删除</Button>
      </template>
    </Column>
  </DataTable>
  <Toast/>
  <add-or-update-admin-user v-on:close="getData" ref="addOrUpdate" v-if="addOrUpdateShow"></add-or-update-admin-user>
</template>

<script>
import AddOrUpdateAdminUser from "@/views/modules/adminUser/addOrUpdateAdminUser";
export default {
  name: "adminUserList",
  components: {AddOrUpdateAdminUser},
  data() {
    return {
      list: [],
      name:'',
      addOrUpdateShow:false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      let ths= this;
      this.$http.get('/adminUser/getList', {
        params: {
          name:ths.name
        }
      }).then((res) => {
        console.log(res.data)
        ths.list=res.data
      })
    },
    addOrUpdate(data){
      let ths =this
      this.addOrUpdateShow=true
      this.$nextTick(()=>{
        ths.$refs.addOrUpdate.init(data)
      })
    },
    deleteUser(id){
      let ths= this
      this.$http.post('/adminUser/delUser/'+id).then(()=>{
        ths.getData()
        ths.$toast.add({severity:'success', summary: '删除成功', life: 3000});
      })
    },
    superUser(id){
      let ths= this
      this.$http.post('/adminUser/superUser/'+id).then(()=>{
        ths.getData()
        ths.$toast.add({severity:'success', summary: '设置成功', life: 3000});
      })
    }
  }
}
</script>

<style scoped>

</style>