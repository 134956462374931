<template>
  <Dialog v-model:visible="show"
          style="width: 30%"
          :header="data.id==null?'添加用户':'编辑用户'">
    <div style="margin: 0 10%">
      <div class="p-field">
        <label style="width: 100px" for="name">用户名：</label>
        <InputText style="width: calc(100% - 100px)" v-model="data.name" id="name" type="text"/>
      </div>
      <div class="p-field">
        <label style="width: 100px" for="password">密码：</label>
        <InputText style="width: calc(100% - 100px)" v-model="data.password" id="password" type="password"/>
      </div>
      <div class="p-field">
        <label style="width: 100px" for="photo">电话：</label>
        <InputNumber style="width: calc(100% - 100px)" v-model="data.photo" id="photo" :useGrouping="false" :maxFractionDigits="11"/>
      </div>
      <div class="p-field">
        <label style="width: 100px" for="remake">备注：</label>
        <Textarea style="width: calc(100% - 100px);" v-model="data.remark" id="remake" type="text"/>
      </div>
      <div class="p-field">
        <Button @click="submit" style="margin-left: 100px">提交</Button>
      </div>
    </div>
  </Dialog>
  <Toast/>
</template>

<script>
export default {
  name: "addOrUpdateAdminUser",
  data() {
    return {
      show: false,
      data:{
        id:null,
        name:null,
        password:null,
        photo:null,
        remark:null,
      },
    }
  },
  methods:{
    init(data){
      this.show=true
      this.data=data
    },
    submit(){
      let ths =this
      this.$http.post('/adminUser/addOrUpdate',this.data).then(()=>{
        this.$toast.add({severity:'success', summary: '成功', life: 3000});
        setTimeout(()=>{
          ths.show=false
          ths.$emit('close',true)
        })
      })
    },
  }
}
</script>

<style scoped>

</style>